import logo from "./logo.svg";
import "./App.css";
import { Link, Route, Routes } from "react-router-dom";
import Users from "./Users";
import Home from "./Home";
import { useState, useEffect } from "react";

function App() {
  const [reloadCount, setReloadCount] = useState(0);

  useEffect(() => {
    const count = localStorage.getItem("reloadCount") || 0;
    const newCount = Number(count) + 1;
    setReloadCount(newCount);
    localStorage.setItem("reloadCount", newCount);
  }, []);

  setTimeout(() => {
    window.location.reload();
  }, 10000);

  return (
    <div className="App">
      <header className="">
        <img src={logo} className="App-logo" alt="logo" />
        <ul>
          <li>
            <Link to="/users">Users</Link>
          </li>
          <li>
            <Link to="/">Home</Link>
          </li>
        </ul>
      </header>

      <p>Stranica je osvežena {reloadCount} puta.</p>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/users" element={<Users />} />
      </Routes>
    </div>
  );
}

export default App;
