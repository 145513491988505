import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Users = () => {
  return (
    <Fragment>
      <h1>Nemanja Milovanovic - Sokoj 1</h1>
      <Link to="/">Home</Link>
    </Fragment>
  );
};

export default Users;
