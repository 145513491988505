export function register() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          registration.addEventListener("updatefound", () => {
            const newWorker = registration.installing;
            if (newWorker) {
              newWorker.addEventListener("statechange", () => {
                if (
                  newWorker.state === "installed" &&
                  navigator.serviceWorker.controller
                ) {
                  console.log("New version available! Reloading...");
                  window.location.reload(); // Automatski osvežava aplikaciju
                }
              });
            }
          });
        })
        .catch((error) =>
          console.log("Service Worker registration failed:", error)
        );
    });
  }
}
